import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { en } from 'src/i18n/locales/en/en';
import { es } from 'src/i18n/locales/es/es';

export const resources = {
  en,
} as const;

// eslint-disable-next-line @typescript-eslint/no-floating-promises
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    react: {
      transKeepBasicHtmlNodesFor: ['strong'],
    },
    detection: {
      order: ['cookie', 'localStorage', 'sessionStorage'],
    },
    load: 'languageOnly',
    ns: [],
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default: https://github.com/i18next/react-i18next/blob/b7c76d9fd9b1dc0dfeb94f27bded17ed2f3ca2ef/example/react/src/i18n.js#L22
    },
    resources: {
      en,
      es,
    },
  });

export default i18n;
