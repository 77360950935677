import React, { memo, useCallback, useEffect } from 'react';
import { useService } from 'react-service-locator';
import notification from 'antd/lib/notification';
import {
  ErrorBoundary,
  globalErrorNotificationKey,
} from 'src/components/error-boundary/error-boundary';
import { NotificationProvider } from 'src/components/general/feedback/hooks/use-show-notification';
import { AuthenticatedRouter } from 'src/components/routing/authenticated-router/authenticated-router';
import { UnauthenticatedRouter } from 'src/components/routing/unauthenticated-router';
import { useAuthorization } from 'src/hooks/use-authorization';
import { useHistoryChange } from 'src/hooks/use-history-change';
import { AppStatusService } from 'src/services/app-status.service';
import { ErrorService } from 'src/services/error.service';
import { SessionService } from 'src/services/session.service';

const useNewBuildVersionUpdate = () => {
  const { getFeatureFlag } = useAuthorization();
  const {
    state: { newVersionAvailable },
    currentBuild,
  } = useService(AppStatusService);

  //Force refresh if the client build date is older than the minimum required
  const minimumClientBuildDateRequired = getFeatureFlag(
    'config-minimum-client-build-date-required',
  );

  useEffect(() => {
    if (!newVersionAvailable || !minimumClientBuildDateRequired) {
      return;
    }

    if (new Date(currentBuild.date) < new Date(minimumClientBuildDateRequired)) {
      window.location.reload();
    }
  }, [newVersionAvailable, minimumClientBuildDateRequired, currentBuild]);

  const refreshPageOnNewVersion = useCallback(() => {
    if (newVersionAvailable) {
      window.location.reload();
    }
  }, [newVersionAvailable]);

  useHistoryChange(refreshPageOnNewVersion);
};

export const Landing = memo(() => {
  useNewBuildVersionUpdate();
  const sessionService = useService(SessionService, (s) => [s.state.sessionData]);
  sessionService.suspendAndInitialize();
  const errorService = useService(ErrorService);
  errorService.suspendAndInitialize();

  const {
    state: { sessionData },
  } = sessionService;

  const closeGlobalErrorNotification = useCallback(() => {
    notification.close(globalErrorNotificationKey);
  }, []);

  useHistoryChange(closeGlobalErrorNotification);

  return (
    <ErrorBoundary>
      <NotificationProvider>
        {sessionData ? <AuthenticatedRouter /> : <UnauthenticatedRouter />}
      </NotificationProvider>
    </ErrorBoundary>
  );
});
