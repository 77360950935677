import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { useService, useServiceSelector } from 'react-service-locator';
import Menu from 'antd/lib/menu';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { Icon } from 'src/components/general/icon';
import { DropdownMenu } from 'src/components/layout/authenticated-layout/header/dropdown';
import { useAuthorization } from 'src/hooks/use-authorization';
import { useFormatDate } from 'src/hooks/use-format-date';
import { AppStatusService } from 'src/services/app-status.service';
import { AuthorizationService } from 'src/services/authorization.service';
import { SessionService } from 'src/services/session.service';
import { getInstitutionPathPrefix } from 'src/utils/get-institution-path-prefix';

const StyledMenuItem = styled(Menu.Item)`
  color: ${(props: any) => props.theme.colors.base.lightBlueGrey};
  font-size: 13px;
  height: 40px;
  letter-spacing: -0.13px;
  line-height: 20px;
  margin: 0 !important;
  padding: 10px 9px;
  text-align: left;

  & .anticon {
    color: #4b6882;
  }

  &:hover {
    background: #98a9bc1f 0 0 no-repeat padding-box;
    border-radius: 4px;
    color: ${(props: any) => props.theme.colors.base.lightBlueGrey};
  }

  ${up('md')} {
    align-items: center;
    display: flex;
    padding: 10px 10px;

    a {
      padding-left: 10px;
    }
  }
`;

export const Span = styled.span`
  color: ${(props) => props.theme.colors.base.lightBlueGrey};
`;

type LinkMenuItemProps = WithClassname & {
  key: number;
  icon: string;
  label: string;
  to: string;
};

const ItemIcon = styled(Icon)`
  margin-right: 13px;
`;

const LinkMenuItem = memo<LinkMenuItemProps>(({ key, icon, label, ...rest }) => {
  return (
    <StyledMenuItem key={key} {...rest}>
      <NavLink to={rest.to}>
        <div style={{ display: 'inline-flex', alignItems: 'center' }}>
          <ItemIcon type={icon} />
          <Span>{label}</Span>
        </div>
      </NavLink>
    </StyledMenuItem>
  );
});

type MenuItemProps = WithClassname & {
  key: number;
  icon: string;
  label: string;
  onClick: VoidFunction;
};

export const MenuItem = memo<MenuItemProps>(({ key, icon, label, ...rest }) => {
  return (
    <StyledMenuItem key={key} {...rest}>
      <div style={{ display: 'inline-flex', alignItems: 'center' }}>
        <Icon type={icon} style={{ marginRight: 13 }} />
        <Span>{label}</Span>
      </div>
    </StyledMenuItem>
  );
});

const MenuDivider = styled(Menu.Divider)`
  &.ant-menu-item-divider {
    background-color: rgba(152, 169, 188, 0.16);
    margin: 10px -10px;
  }
`;

type UserAccountMenuProps = WithClassname & { showDivider?: boolean };

const StyledAppVersion = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 11px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.11px;
  line-height: 13px;
  opacity: 0.7;
  padding-left: 10px;
  text-align: left;

  ${up('md')} {
    color: ${({ theme }) => theme.colors.flint};
    opacity: 1;
    padding-top: 6px;
  }
`;

const AppVersion = memo(({ version }: { version: string }) => {
  const { t } = useTranslation(['common']);

  return <StyledAppVersion>{t('common:releaseDate', { version })}</StyledAppVersion>;
});

const useAppVersion = () => {
  const currentBuild = useServiceSelector(AppStatusService, (s) => s.currentBuild);
  const { formatDate } = useFormatDate();

  return useMemo(
    () => formatDate(new Date(currentBuild.date), 'YYYY-MM-DD HH:mm:ss', false),
    [currentBuild.date, formatDate],
  );
};

export const UserAccountMenu = memo<UserAccountMenuProps>(
  ({ showDivider = true, ...props }) => {
    const { url: institutionUrl } = useRouteMatch();
    const { logout } = useService(SessionService, () => []);
    const { hasPermission } = useAuthorization();
    const {
      state: {
        institution,
        role,
        concreteInstitutionLineage: { agency, organization },
      },
    } = useService(AuthorizationService, (s) => [s.state.institution, s.state.role]);
    const { t } = useTranslation(['header', 'common']);
    const signOut = useCallback(async () => {
      await logout();
      window.location.href = '/';
    }, [logout]);

    const internalSalesTeamCompanyId = parseInt(
      import.meta.env.VITE_LEADGOGO_SALES_TEAM_COMPANY_ID,
    );

    const appVersion = useAppVersion();

    const showExecutiveItem =
      (internalSalesTeamCompanyId &&
        institution.id === internalSalesTeamCompanyId &&
        role.id === 'company.agent') ||
      hasPermission('account.executive');

    return (
      <DropdownMenu {...props}>
        {hasPermission('system.admin') && (
          <LinkMenuItem key={0} to="/" icon="system" label={t('common:system')} />
        )}
        {showExecutiveItem && (
          <LinkMenuItem
            key={1}
            to="/"
            icon="system"
            label={t('common:accountExecutives')}
          />
        )}
        {hasPermission('agency:access') && agency && (
          <LinkMenuItem
            key={2}
            to={getInstitutionPathPrefix('Agency', agency.id)}
            icon="agency"
            label={t('common:agency')}
          />
        )}
        {organization &&
          hasPermission('organization:access') &&
          institution.type === 'Company' && (
            <LinkMenuItem
              key={3}
              to={getInstitutionPathPrefix('Organization', organization.id)}
              icon="institution"
              label={t('header:userActions.organizationSettings')}
            />
          )}
        {hasPermission('organization:billing.access') &&
          institution.type === 'Organization' && (
            <LinkMenuItem
              key={4}
              to={`${institutionUrl}/settings/billing/edit`}
              icon="billing"
              label={t('common:billing')}
            />
          )}
        <LinkMenuItem
          key={5}
          to={`${institutionUrl}/_actions/account/view/`}
          icon="gear"
          label={t('header:userActions.profileSettings')}
        />
        {showDivider && <MenuDivider />}
        <MenuItem
          key={6}
          onClick={signOut}
          icon="logout"
          label={t('header:userActions.logOut')}
        />
        {appVersion && (
          <>
            <MenuDivider />
            <AppVersion version={appVersion} />
          </>
        )}
      </DropdownMenu>
    );
  },
);
