import React, { memo } from 'react';
import { useService } from 'react-service-locator';
import Col from 'antd/lib/col';
import Collapse from 'antd/lib/collapse';
import Row from 'antd/lib/row';
import styled from 'styled-components';
import { BorderedAvatar } from 'src/components/general/display/avatar';
import { Icon } from 'src/components/general/icon';
import { UserAccountMenu } from 'src/components/layout/authenticated-layout/user-account-menu';
import { useSidebar } from 'src/hooks/use-sidebar';
import { AuthorizationService } from 'src/services/authorization.service';
import { SessionService } from 'src/services/session.service';

const { Panel } = Collapse;

const TruncatedCol = styled(Col)`
  min-width: 0;

  > span,
  > small {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const IconCol = styled(Col)`
  margin-left: 10px;
  line-height: 0;
`;

const UserControlsInfo = memo(() => {
  const {
    data: {
      user: { firstName },
    },
  } = useService(SessionService, (s) => [s.data.user.email]);
  const {
    state: { role },
  } = useService(AuthorizationService, (s) => [s.state.role]);
  const { isSidebarCollapsed } = useSidebar();

  return (
    <Row align="middle" wrap={false}>
      <Col style={{ marginRight: '20px' }}>
        <BorderedAvatar />
      </Col>
      <TruncatedCol flex="auto">
        <span>{firstName}</span>
        <small>{role.name}</small>
      </TruncatedCol>
      {!isSidebarCollapsed && (
        <IconCol>
          <Icon type="arrowdown" />
        </IconCol>
      )}
    </Row>
  );
});

export const UserControls = memo(() => {
  return (
    <Container>
      <Collapse expandIcon={() => null}>
        <Panel header={<UserControlsInfo />} key="1">
          <StyledUserAccountMenu showDivider={false} />
        </Panel>
      </Collapse>
    </Container>
  );
});

const StyledUserAccountMenu = styled(UserAccountMenu)`
  &.ant-menu {
    background: unset;
    border-radius: unset;
    border: unset;
    box-shadow: unset;
    padding: 0;

    .ant-menu-item {
      height: 45px;
      line-height: 14px;
      padding: 10px !important;
      align-items: center;
      display: flex;
      background-color: transparent;
      font-size: 14px;
    }

    .ant-menu-item span {
      color: ${({ theme }) => theme.colors.halfWhite} !important;
    }

    .ant-menu-item:hover {
      background: unset;
      border-radius: unset;
      color: ${({ theme }) => theme.colors.halfWhite} !important;
    }

    .ant-menu-item:hover span {
      color: #fff !important;
    }

    .ant-menu-item .anticon {
      color: ${({ theme }) => theme.colors.lightGrey6};
      margin-right: 10px;
    }
  }
`;

const Container = styled.div`
  .ant-collapse,
  .ant-collapse .ant-collapse-content,
  .ant-collapse .ant-collapse-item,
  .ant-collapse .ant-collapse-item > .ant-collapse-header {
    border-radius: 0 !important;
    border: none;
    background: #2b90cf;
    line-height: 15px;
  }

  .ant-collapse .ant-collapse-header {
    height: 60px;
    padding: 11px 23px 11px 16px !important;
  }

  .ant-collapse .ant-collapse-item .ant-collapse-content {
    border-radius: 0 !important;
  }

  .ant-collapse .ant-collapse-item-active .ant-collapse-header .ant-collapse-arrow {
    -webkit-transform: rotate(180deg) translateY(2px);
    -ms-transform: rotate(180deg) translateY(2px);
    transform: rotate(180deg) translateY(2px);
  }

  .ant-collapse-icon-position-right
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    right: 21px;
  }

  .ant-collapse .ant-list-split .ant-list-item {
    border-bottom: none;
  }

  .ant-collapse .ant-collapse-content-box {
    padding: 10px 15px;
  }

  .ant-collapse .ant-collapse-content .ant-list-item,
  .ant-collapse .ant-collapse-content .ant-list-item a,
  .ant-collapse .ant-collapse-content .ant-list-item a .lgg-icon {
    color: rgba(255, 255, 255, 0.5) !important;
    vertical-align: middle;
    line-height: 14px;
  }

  .ant-collapse .ant-collapse-header {
    color: #ffffff !important;
    font-size: 15px;
  }

  .ant-collapse .ant-collapse-header small {
    color: rgba(255, 255, 255, 0.65);
    font-size: 12px;
    margin-top: 1px;
    display: block;
  }

  .ant-collapse .ant-list-item {
    height: 45px;
    line-height: 30px;
    padding: 10px;
  }

  .ant-collapse .ant-collapse-content-active .ant-collapse-content-box {
    border-top: 1px solid rgba(152, 169, 188, 0.22);
  }
`;
