import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { Conversation } from '@lgg/isomorphic/types/__generated__/graphql';
import { Icon } from 'src/components/general/icon';
const StyledConversationStatus = styled.span`
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 14px;
  min-height: 14px;
  text-align: right;

  ${up('md')} {
    font-size: 11px;
    line-height: 13px;
  }
`;

const StatusIcon = styled(Icon)`
  margin-right: 3px;
`;

type ConversationStatusProps = Pick<Conversation, 'isReplyPending'>;

export const ConversationStatus = memo<ConversationStatusProps>(({ isReplyPending }) => {
  const { t } = useTranslation(['conversations']);

  if (!isReplyPending) {
    return null;
  }

  return (
    <StyledConversationStatus>
      <StatusIcon type="unactive" />
      {t('conversations:conversationStatus.notReplied')}
    </StyledConversationStatus>
  );
});
