import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import ReactSelect, {
  components,
  DropdownIndicatorProps,
  ClearIndicatorProps,
  OptionProps,
  GroupBase,
  Props,
  MultiValueGenericProps,
  MultiValueRemoveProps,
  ControlProps,
  MenuListProps,
  InputProps,
  ValueContainerProps,
  OnChangeValue,
  MultiValue,
  MenuProps,
  MultiValueProps,
  InputActionMeta,
} from 'react-select';
import AntDrawer from 'antd/lib/drawer';
import c from 'classnames';
import { debounce, kebabCase } from 'lodash';
import Lottie from 'lottie-react';
import CreatableSelect from 'react-select/creatable';
import { up } from 'styled-breakpoints';
import styled, { css } from 'styled-components';
import loadingAnimation from 'src/assets/animations/select_spinner.json';
import { FlexColumn } from 'src/components/domain/contacts/contact-details/shared';
import { ButtonV2, StyledButtonV2 } from 'src/components/general/button/lgg-button';
import {
  Chip,
  TextChip,
  ImageChip,
  BaseChipProps,
} from 'src/components/general/display/chip';
import { useBottomDrawerHeight } from 'src/components/general/drawer/bottom/bottom-drawer';
import { Icon } from 'src/components/general/icon';
import { Expand } from 'src/components/layout/expand';
import { FlexRow } from 'src/components/layout/flex-row';
import { SafeAreaBottom } from 'src/components/providers/safe-area-insets-provider';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useGetVisualViewport } from 'src/hooks/use-get-visual-viewport';
import { useVisible } from 'src/hooks/use-visible';
import {
  inputBorder,
  inputFocusBorder,
  labelTextStyle,
} from 'src/theme/sub-themes/inputs-theme';

export type SelectOption<T = string | number> = {
  label: string;
  value: T;
  isDisabled?: boolean;
  chip?: TextChip | ImageChip;
};

export type GroupedSelectOption<T = string | number> = {
  label: string;
  options: SelectOption<T>[];
};

const SelectWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const SelectInnerWrapper = styled.div`
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
`;

const selectTextStyles = css`
  color: ${({ theme }) => theme.colors.smalt};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 17px;
  text-align: left;
`;

const selectStyles = css`
  width: 100%;

  // Container

  .lgg-select__control {
    ${inputBorder};
    width: 100%;
    min-height: 38px;
    padding: 8px 10px 8px 10px;

    &:hover {
      ${inputBorder};
    }

    // Container disable state

    &.lgg-select__control--is-disabled {
      background-color: ${({ theme }) => theme.colors.porcelain};
    }

    // Container focused state

    &.lgg-select__control--is-focused {
      ${inputFocusBorder};
    }

    // Container menu open state

    &.lgg-select__control--menu-is-open {
      // Arrow icon

      .lgg-select__indicator .lgg-icon.arrow-icon {
        transform: rotate(180deg);
      }
    }

    ${up('md')} {
      padding-right: 0;
    }
  }

  &.input-error {
    .lgg-select__control {
      ${({ theme }) => theme.inputsTheme.errorBorder};
    }
  }

  // Container drawer

  &.lgg-drawer-select {
    .control-container:not(.is-searchable) {
      display: none;
    }

    .control-container {
      border-bottom: solid 1px ${({ theme }) => theme.colors.koala};
      padding: 10px 15px;
    }
  }

  // Styles normalizer

  .lgg-select__control .lgg-select__value-container,
  .lgg-select__group {
    padding: 0;
  }

  .lgg-select__control .lgg-select__value-container > input {
    height: 0;
  }

  // Placeholder

  .lgg-select__placeholder {
    color: ${({ theme }) => theme.colors.geyser};
    font-family: ${({ theme }) => theme.font.regular};
    font-size: 13px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: 17px;
    margin: 0;
    text-wrap: nowrap;
  }

  &.lgg-drawer-select {
    .lgg-select__placeholder {
      margin-left: 26px;
    }
  }

  // Selected value when !isMulti

  .lgg-select__control {
    .lgg-select__single-value {
      ${selectTextStyles};
    }
  }

  .control-container.is-searchable {
    .lgg-select__control.lgg-select__control--menu-is-open {
      // Selected value when !isMulti

      .lgg-select__single-value {
        ${selectTextStyles};
        color: ${({ theme }) => theme.colors.geyser};
      }
    }
  }

  // Selected values when isMulti (tags)

  .lgg-select__multi-value {
    background-color: ${({ theme }) => theme.colors.porcelain};
    border-radius: 11px;
    padding: 5px 0 4px 6px;
    // Text

    .lgg-select__multi-value__label {
      align-items: center;
      display: flex;
      flex-direction: row;
      padding: 0;
    }

    // Clear tag icon

    .lgg-select__multi-value__remove {
      cursor: pointer;
      padding: 0 6px;

      &:hover {
        background-color: unset;
      }
    }
  }

  &.is-tag-select .lgg-select__multi-value {
    background-color: ${({ theme }) => theme.colors.secondaryMint20};
  }

  &:not(.lgg-drawer-select) {
    .lgg-select__value-container--is-multi.lgg-select__value-container--has-value {
      margin: -5px;
    }
  }

  // Search input styles

  .lgg-select__input-container {
    ${selectTextStyles};
    margin: 0;
    padding: 0;
  }

  &.lgg-drawer-select {
    .lgg-select__input-container {
      margin-left: 26px;
    }
  }

  // Indicators container (arrow, clear, search, loading)

  .lgg-select__indicators {
    align-items: center;
    align-self: center;
    display: flex;
    height: 100%;
    padding: 0 15px 0 10px;

    .lgg-select__indicator {
      padding: 0;
    }

    .lgg-icon.arrow-icon {
      transition: transform 150ms;

      path {
        fill: ${({ theme }) => theme.colors.casper};
      }
    }
  }

  // Options overlay menu

  .lgg-select__menu {
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 0;
    border: none;
    box-shadow: none;
    margin-top: 0;

    ${up('md')} {
      border-radius: 6px;
      border: solid 1px ${({ theme }) => theme.colors.koala};
      box-shadow: 0 20px 40px 0 #5b65701a;
      margin-top: 3px;
    }
  }

  .lgg-select__menu-list {
    padding: 15px;

    ${up('md')} {
      padding: 5px;
    }
  }

  &.lgg-drawer-select.is-multi-select {
    &.is-showing-selected-count,
    &.is-multi-grouped-select:not(.is-showing-selected-count) {
      .lgg-select__menu-list {
        padding-top: 0;
      }
    }
  }

  &.lgg-drawer-select.is-grouped-select {
    .lgg-select__menu-list {
      padding-top: 0;
    }
  }

  // Options

  .lgg-select__option {
    background-color: ${({ theme }) => theme.colors.porcelain};
    border-radius: 6px;
    padding: 0;

    &:active:not(.lgg-select__option--is-selected) {
      background-color: ${({ theme }) => theme.colors.porcelain};
    }

    // Focus options

    &.lgg-select__option--is-focused:not(.lgg-select__option--is-disabled):not(.lgg-select__option--is-selected) {
      background-color: ${({ theme }) => theme.colors.porcelain};
    }

    // Loading item

    &.loading-item,
    &.lgg-select__option--is-focused.loading-item:not(.lgg-select__option--is-disabled):not(.lgg-select__option--is-selected) {
      background-color: ${({ theme }) => theme.colors.white};
    }

    // Selected option

    &.lgg-select__option--is-selected {
      background-color: ${({ theme }) => theme.colors.secondaryTopaz10};
    }

    ${up('md')} {
      background-color: ${({ theme }) => theme.colors.white};
      border-radius: 4px;

      &:active:not(.lgg-select__option--is-selected) {
        background-color: ${({ theme }) => theme.colors.white};
      }
    }
  }

  .lgg-select__option + .lgg-select__option {
    margin-top: 8px;

    ${up('md')} {
      margin-top: 3px;
    }
  }

  // Group header

  .lgg-select__group-heading {
    border-bottom: solid 1px ${({ theme }) => theme.colors.porcelain};
    color: ${({ theme }) => theme.colors.smalt};
    font-family: ${({ theme }) => theme.font.medium};
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.12px;
    line-height: 14px;
    margin: 3px 0;
    padding: 12px 10px;
    text-align: left;
    text-transform: unset;
  }

  &.lgg-drawer-select {
    .lgg-select__group-heading {
      border-bottom: none;
      padding-left: 0;
      text-transform: uppercase;
    }
  }
}
`;

const StyledSelect = styled(ReactSelect)`
  ${selectStyles};
` as typeof ReactSelect;

const StyledCreatableSelect = styled(CreatableSelect)`
  ${selectStyles};
` as typeof CreatableSelect;

const InvisibleElement = styled.div`
  display: none;
`;

const SearchIcon = styled(Icon)`
  svg {
    height: 12px;
    width: 12px;

    path {
      fill: ${({ theme }) => theme.colors.casper};
    }
  }

  .lgg-drawer-select & {
    svg {
      height: 16px;
      width: 16px;
    }
  }
`;

const LoadingSpinner = (props: { size?: string }) => {
  const { size = '16px' } = props;

  return (
    <Lottie
      animationData={loadingAnimation}
      loop={true}
      style={{ height: size, width: size }}
    />
  );
};

const DropdownIndicator = <
  SelectOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<SelectOption> = GroupBase<SelectOption>,
>(
  props: DropdownIndicatorProps<SelectOption, IsMulti, Group> & { forceCaret?: boolean },
) => {
  const {
    selectProps: { isLoading, isDisabled, isSearchable },
    isFocused,
    hasValue,
    forceCaret = false,
  } = props;

  const renderTrailingIcon = useCallback(() => {
    const arrowIcon = () => <Icon className="arrow-icon" type="arrowdown" />;

    if (forceCaret || isDisabled) {
      return arrowIcon();
    }

    if (isLoading) {
      return <LoadingSpinner />;
    }

    if (isSearchable && isFocused && !hasValue) {
      return <SearchIcon type="magnifyingGlass" />;
    }

    if (!hasValue) {
      return arrowIcon();
    }

    return <InvisibleElement />;
  }, [forceCaret, hasValue, isDisabled, isFocused, isLoading, isSearchable]);

  return (
    <components.DropdownIndicator {...props}>
      {renderTrailingIcon()}
    </components.DropdownIndicator>
  );
};

// These components return `null` to override react-select defaults
const IndicatorSeparator = () => {
  return null;
};

const LoadingIndicator = () => {
  return null;
};

const ClearIcon = styled(Icon)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.koala};
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: 14px;
  justify-content: center;
  width: 14px;

  svg {
    height: 6px;
    width: 6px;

    path {
      fill: ${({ theme }) => theme.colors.steel};
    }
  }
`;

const ClearIndicator = <
  SelectOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<SelectOption> = GroupBase<SelectOption>,
>(
  props: ClearIndicatorProps<SelectOption, IsMulti, Group>,
) => {
  const {
    innerProps: { ref, ...restInnerProps },
    selectProps: { isLoading },
  } = props;

  if (isLoading) {
    return <InvisibleElement />;
  }

  return (
    <div {...restInnerProps} ref={ref} data-lgg-id="clear-icon">
      <ClearIcon type="close" />
    </div>
  );
};

const StyledOption = styled(FlexRow)`
  align-items: center;
  height: 40px;
  padding: 0 15px 0 10px;

  ${up('md')} {
    height: 32px;
    padding: 0 10px;
  }
`;

const OptionLabel = styled.span`
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 18px;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;

  .lgg-select__option--is-selected & {
    color: ${({ theme }) => theme.colors.gogo};
  }

  .lgg-select__option--is-disabled & {
    color: ${({ theme }) => theme.colors.flint};
    cursor: not-allowed;
    opacity: 0.5;
  }

  ${up('md')} {
    font-size: 12px;
    line-height: 14px;
  }
`;

const SelectedItemIcon = styled(Icon)`
  svg {
    height: 16px;
    width: 16px;

    path {
      fill: ${({ theme }) => theme.colors.gogo};
    }

    ${up('md')} {
      height: 12px;
      width: 12px;
    }
  }
`;

const StyledLoadingItem = styled(FlexRow)`
  align-items: center;
  height: 40px;
  justify-content: center;

  ${up('md')} {
    height: 32px;
  }
`;

const LoadingItem = () => {
  return (
    <StyledLoadingItem
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <LoadingSpinner size="20px" />
    </StyledLoadingItem>
  );
};

const OptionComponent = <
  Option extends SelectOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: OptionProps<Option, IsMulti, Group> & { chipSize: BaseChipProps['size'] },
) => {
  const {
    data: { label, chip, value },
    isSelected,
    selectProps: { name },
    chipSize,
  } = props;

  const parsedValue = `${value.toString().replaceAll(':', '_')}`;
  const isLoadingItem = value === LOADING_ITEM.value;

  return (
    <components.Option
      {...props}
      className={c({
        'loading-item': isLoadingItem,
      })}
    >
      {isLoadingItem ? (
        <LoadingItem />
      ) : (
        <StyledOption data-lgg-id={`select-option-${name}-${parsedValue}`}>
          {chip && <Chip size={chipSize} {...chip} />}
          <OptionLabel>{label}</OptionLabel>
          <Expand />
          {isSelected && <SelectedItemIcon type="checkSave" />}
        </StyledOption>
      )}
    </components.Option>
  );
};

const TagLabel = styled.span`
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 11px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 13px;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;

  .is-tag-select & {
    color: ${({ theme }) => theme.colors.secondaryMintDark};
  }
`;

const MultiValueLabel = <
  Option extends SelectOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: MultiValueGenericProps<Option, IsMulti, Group>,
) => {
  const {
    data,
    selectProps: { name },
  } = props;

  return (
    <components.MultiValueLabel {...props}>
      <FlexRow
        style={{ overflow: 'hidden' }}
        data-lgg-id={`${name}-${kebabCase(data.label)}`}
      >
        {data.chip && <Chip size="small" {...data.chip} />}
        <TagLabel>{props.children}</TagLabel>
      </FlexRow>
    </components.MultiValueLabel>
  );
};

const RemoveTagIcon = styled(Icon)`
  svg {
    height: 8px;
    width: 8px;

    path {
      fill: ${({ theme }) => theme.colors.casper};
    }
  }
`;

const MultiValueRemove = <
  Option extends SelectOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: MultiValueRemoveProps<Option, IsMulti, Group>,
) => {
  const {
    selectProps: { name },
    data: { label },
  } = props;

  return (
    <components.MultiValueRemove {...props}>
      <RemoveTagIcon type="close" lggTestId={`${name}-close-icon-${kebabCase(label)}`} />
    </components.MultiValueRemove>
  );
};

const StyledNoOptionsFound = styled.div`
  color: ${({ theme }) => theme.colors.geyser};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 14px;
  text-align: left;
`;

const NoOptionsFound = () => {
  const { t } = useTranslation(['common']);

  return <StyledNoOptionsFound>{t('common:noOptions')}</StyledNoOptionsFound>;
};

const Label = styled.span`
  ${labelTextStyle};
  margin-bottom: 1px;
`;

const Control = <
  Option extends SelectOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: ControlProps<Option, IsMulti, Group>,
) => {
  const {
    children,
    selectProps: { isSearchable, name },
  } = props;

  return (
    <div
      className={`control-container ${isSearchable ? 'is-searchable' : ''}`}
      data-lgg-id={name}
    >
      <components.Control {...props}>{children}</components.Control>
    </div>
  );
};

export function multiValueAsValue<Option, IsMulti extends boolean>(
  multiValue: MultiValue<Option>,
): OnChangeValue<Option, IsMulti> {
  return multiValue as OnChangeValue<Option, IsMulti>;
}

const SelectedCountLabel = styled(FlexRow)`
  color: ${({ theme }) => theme.colors.gogo};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 14px;
  padding-top: 12px;
  text-align: left;
`;

const SelectedChip = styled(FlexRow)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.secondaryTopaz10};
  border-radius: 20px;
  color: ${({ theme }) => theme.colors.gogo};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  height: 40px;
  letter-spacing: normal;
  line-height: 18px;
  padding: 11px 15px;
  text-align: left;
  white-space: nowrap;

  .is-tag-select & {
    background-color: ${({ theme }) => theme.colors.secondaryMint20};
    color: ${({ theme }) => theme.colors.secondaryMintDark};
  }

  svg {
    height: 14px;
    margin-left: 10px;
    width: 14px;

    path {
      fill: ${({ theme }) => theme.colors.geyser};
    }
  }

  & + & {
    margin-left: 8px;
  }
`;

const SelectionContainer = styled(FlexColumn)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.koala};
  margin-bottom: 15px;
  margin-left: -15px;
  margin-right: -15px;
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 15px 15px 15px;

  .is-multi-grouped-select & {
    margin-bottom: 0;
  }
`;

const buttonsBarHeight = 51;

const useScrollBottomLoadMore = () => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const isLoadingMoreRef = useRef(false);
  const { onLoadMore } = useContext(SelectContext);

  useEffect(() => {
    const menu = containerRef.current;

    if (menu && onLoadMore && !isLoadingMoreRef.current) {
      const onScroll = async () => {
        if (menu.scrollTop + menu.clientHeight >= menu.scrollHeight - 50) {
          isLoadingMoreRef.current = true;
          await onLoadMore();
          isLoadingMoreRef.current = false;
        }
      };

      menu.addEventListener('scroll', onScroll);

      return () => {
        menu?.removeEventListener('scroll', onScroll);
      };
    }
  }, [onLoadMore]);

  return {
    containerRef,
  };
};

const DesktopMenuList = <
  Option extends SelectOption = SelectOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: MenuListProps<Option, IsMulti, Group>,
) => {
  const { ...rest } = props;
  const { isSearching } = useContext(SelectContext);
  const { containerRef } = useScrollBottomLoadMore();

  if (isSearching) {
    return <LoadingItem />;
  }

  return (
    <components.MenuList
      {...rest}
      innerRef={(ref) => (containerRef.current = ref)}
      maxHeight={props.maxHeight - buttonsBarHeight}
    >
      {props.children}
    </components.MenuList>
  );
};

const MenuList = <
  Option extends SelectOption = SelectOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: MenuListProps<Option, IsMulti, Group>,
) => {
  const { t } = useTranslation(['common']);
  const {
    selectProps: { value, inputValue },
    setValue,
    isMulti,
  } = props;

  const { containerRef } = useScrollBottomLoadMore();
  const { isSearching } = useContext(SelectContext);

  if (isSearching) {
    return <LoadingItem />;
  }

  return (
    <components.MenuList {...props} innerRef={(ref) => (containerRef.current = ref)}>
      {!inputValue && isMulti && value && 'length' in value && value.length > 0 && (
        <>
          <SelectedCountLabel>{`${value.length} ${t(
            'common:selected',
          ).toUpperCase()}`}</SelectedCountLabel>
          <SelectionContainer>
            <FlexRow>
              {value.map((option) => (
                <SelectedChip
                  key={option.value}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (value) {
                      setValue(
                        multiValueAsValue(value.filter((s) => s.value !== option.value)),
                        'deselect-option',
                        option,
                      );
                    }
                  }}
                >
                  {option.chip && <Chip size="large" {...option.chip} />}
                  {option.label}
                  <Icon type="close" />
                </SelectedChip>
              ))}
            </FlexRow>
          </SelectionContainer>
        </>
      )}
      {props.children}
    </components.MenuList>
  );
};

const ButtonsContainer = styled(FlexRow)`
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.colors.koala};
  height: ${buttonsBarHeight}px;
  padding: 10px;
  width: 100%;

  ${StyledButtonV2} {
    flex: 1;
    height: 30px;
  }

  ${StyledButtonV2} + ${StyledButtonV2} {
    margin-left: 10px;
  }
`;

const Menu = <
  Option extends SelectOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: MenuProps<Option, IsMulti, Group>,
) => {
  const { t } = useTranslation();
  const { clearValue, isMulti } = props;

  return (
    <components.Menu {...props}>
      <span data-lgg-id="select-menu-list">
        {props.children}
        {isMulti && (
          <ButtonsContainer>
            <ButtonV2
              variant="default"
              data-lgg-id="select-clear-button"
              type="button"
              size="small"
              onClick={clearValue}
            >
              {t('common:clear')}
            </ButtonV2>
            <ButtonV2
              variant="primary"
              data-lgg-id="select-apply-button"
              size="small"
              type="button"
              onClick={() => {
                const activeElement = document.activeElement;

                if (activeElement) {
                  (activeElement as HTMLElement).blur();
                }
              }}
            >
              {t('common:done')}
            </ButtonV2>
          </ButtonsContainer>
        )}
      </span>
    </components.Menu>
  );
};

const Input = <
  Option extends SelectOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: InputProps<Option, IsMulti, Group>,
) => {
  return <components.Input {...props}>{props.children}</components.Input>;
};

const ValueContainer = <
  Option extends SelectOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  children,
  ...props
}: ValueContainerProps<Option, IsMulti, Group>) => (
  <components.ValueContainer {...props}>
    <SearchIcon type="magnifyingGlass" style={{ position: 'absolute' }} />
    {children}
  </components.ValueContainer>
);

const StyledMoreItemsSelectedCountChip = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.porcelain};
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.flint};
  display: flex;
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 11px;
  font-stretch: normal;
  font-style: normal;
  height: 22px;
  justify-content: center;
  letter-spacing: normal;
  line-height: 13px;
  padding: 6px;
  text-align: left;

  .is-tag-select & {
    background-color: ${({ theme }) => theme.colors.secondaryMint20};
    color: ${({ theme }) => theme.colors.secondaryMintDark};
  }
`;

type MoreItemsSelectedCountChipProps = {
  count: number;
};

const MoreItemsSelectedCountChip = memo<MoreItemsSelectedCountChipProps>(({ count }) => {
  return <StyledMoreItemsSelectedCountChip>+{count}</StyledMoreItemsSelectedCountChip>;
});

const MultiValueComponent = <
  Option extends SelectOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: MultiValueProps<Option, IsMulti, Group>,
) => {
  const { getValue, index } = props;
  const maxToShow = 5;
  const overflow = getValue().slice(maxToShow);

  return index < maxToShow ? (
    <components.MultiValue {...props}>{props.children}</components.MultiValue>
  ) : index === maxToShow ? (
    <MoreItemsSelectedCountChip count={overflow.length} />
  ) : null;
};

const SelectContainer = styled(FlexColumn)`
  width: 100%;
`;

const DrawerHeader = styled(FlexRow)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.porcelain};
  height: 70px;
  margin-bottom: 0;
  padding: 0 20px;
`;

const DrawerTitle = styled.span`
  color: ${({ theme }) => theme.colors.carbon};
  flex: 1;
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 20px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: -0.4px;
  line-height: 24px;
  margin-right: 20px;
  text-align: left;
`;

const DrawerCloseIcon = styled(Icon)`
  svg {
    height: 18px;
    width: 18px;

    path {
      fill: ${({ theme }) => theme.colors.flint};
    }
  }
`;

const StyledDrawer = styled(AntDrawer).withConfig<{
  menuHeight: number;
}>({
  shouldForwardProp: (prop) => prop !== 'menuHeight',
})`
  .ant-drawer-header {
    border: none;
    padding: 0;
  }

  .ant-drawer-content {
    border-radius: 8px 8px 0 0;
  }

  .ant-drawer-body {
    overflow: hidden;
    padding: 0;
  }

  .ant-drawer-footer {
    padding: 0;
  }

  .lgg-select__menu-list {
    height: ${({ menuHeight }) => menuHeight + 'px'};
    max-height: unset;
  }
`;

const DrawerFooter = styled(FlexRow)`
  align-items: center;
  padding: 15px 20px;

  ${StyledButtonV2} {
    flex: 1;
  }

  ${StyledButtonV2} + ${StyledButtonV2} {
    margin-left: 20px;
  }
`;

type SelectProps<
  Option extends SelectOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> = Omit<Props<Option, IsMulti, Group>, 'onChange'>;

type ExtraProps<Option extends SelectOption, IsMulti extends boolean> = {
  label?: string;
  mobileLabel?: string;
  isCreatable?: boolean;
  className?: string;
  onChange: ValueChanged<OnChangeValue<Option, IsMulti>>;
  isTag?: boolean;
  forceCaret?: boolean;
  onLoadMore?: () => Promise<void>;
  onSearch?: (keyword: string) => Promise<void>;
  canLoadMore?: boolean;
  hasError?: boolean;
};

const SelectContext = React.createContext<{
  onLoadMore?: () => Promise<void>;
  isSearching: boolean;
  setIsLoadingMore: (loading: boolean) => void;
  isLoadingMore: boolean;
}>({
  onLoadMore: async () => {},
  isSearching: false,
  setIsLoadingMore: () => {},
  isLoadingMore: false,
});

const LOADING_ITEM = { value: 'loading_item' };

export const Select = <
  Option extends SelectOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  options,
  value,
  isMulti,
  placeholder,
  onChange,
  isLoading,
  isDisabled,
  menuIsOpen,
  label,
  mobileLabel,
  isSearchable = true,
  isCreatable,
  isClearable = true,
  className,
  name,
  isTag,
  forceCaret = false,
  onLoadMore,
  onSearch,
  canLoadMore,
  hasError,
}: SelectProps<Option, IsMulti, Group> & ExtraProps<Option, IsMulti>) => {
  const { show, close, visible } = useVisible();
  const { t } = useTranslation(['common']);
  const breakpointUpMd = useBreakpoint(up('md'));
  const SelectComponent = isCreatable ? StyledCreatableSelect : StyledSelect;
  const [innerInputValue, setInnerInputValue] = useState<string>();
  const [internalValue, setInternalValue] = useState<typeof value>(value);
  const [menuHeight, setMenuHeight] = useState<number>(0);
  const [isSearching, setIsSearching] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const { visualViewportHeight } = useGetVisualViewport();

  const handleClose = useCallback(() => {
    close();
    setInternalValue(value);
  }, [close, value]);

  const handleClear = useCallback(() => {
    setInternalValue(isMulti ? [] : null);
  }, [isMulti]);

  const handleApply = useCallback(
    (value: OnChangeValue<Option, IsMulti>) => {
      onChange(value);
      handleClose();
    },
    [handleClose, onChange],
  );

  const handleSearch = debounce(async (value) => {
    if (!onSearch) {
      return;
    }

    setIsSearching(true);
    await onSearch?.(value);
    setIsSearching(false);
  }, 500);

  useEffect(() => {
    if (!visible) {
      setInternalValue(value);
    }
  }, [value, visible]);

  const showingSelectedCount = useMemo(
    () =>
      !innerInputValue &&
      isMulti &&
      !!internalValue &&
      'length' in internalValue &&
      internalValue.length > 0,
    [innerInputValue, internalValue, isMulti],
  );

  const showingSelectedCountClassName = showingSelectedCount
    ? ' is-showing-selected-count '
    : '';

  const isMultiClassname = isMulti ? ' is-multi-select ' : '';
  const isTagClassname = isTag ? ' is-tag-select ' : '';
  const isGroupSelectClassname =
    !!options && options.length > 0 && 'options' in options[0] ? 'is-grouped-select' : '';
  const isMultiGroupSelectClassname =
    isMulti && isGroupSelectClassname ? 'is-multi-grouped-select' : '';
  const effectiveOptions = useMemo(
    () =>
      options && options.length > 0 && 'value' in options[0] && canLoadMore
        ? [...options, LOADING_ITEM]
        : options,
    [canLoadMore, options],
  );

  const onInputSearch = useCallback(
    (value: string, actionMeta: InputActionMeta) => {
      // The on input search is called under many conditions, we only want to handle two cases:
      // The user is typing on the input: So we can grab the value make a request to get items matching that keyword
      // The user finished typing and selects an item from the list: This makes the input empty, so we want to refetch in order
      // to see the full list again
      if (
        actionMeta.action === 'input-change' ||
        (actionMeta.action === 'set-value' && actionMeta.prevInputValue)
      ) {
        void handleSearch(value);
      }
    },
    [handleSearch],
  );

  const filterOption = useMemo(() => {
    return canLoadMore ? () => true : undefined;
  }, [canLoadMore]);

  const mainSelect = useMemo(
    () => (
      <SelectContainer className={className}>
        {label && <Label>{label}</Label>}
        <SelectComponent
          classNamePrefix="lgg-select"
          // @ts-expect-error lib complex types
          options={effectiveOptions}
          value={value}
          name={name}
          allowCreateWhileLoading={!isSearching}
          placeholder={placeholder}
          isLoading={isLoading}
          isClearable={isClearable}
          menuPosition="fixed"
          className={`${isTagClassname} ${c({ 'input-error': hasError })}`}
          closeMenuOnScroll
          isSearchable={breakpointUpMd ? isSearchable : false}
          filterOption={filterOption}
          hideSelectedOptions={false}
          isDisabled={isDisabled}
          menuIsOpen={menuIsOpen ?? (breakpointUpMd ? visible : false)}
          onChange={onChange}
          onMenuClose={close}
          isMulti={isMulti}
          onInputChange={onInputSearch}
          noOptionsMessage={() => <NoOptionsFound />}
          onMenuOpen={show}
          closeMenuOnSelect={!isMulti}
          controlShouldRenderValue
          // Override default behaviour because on Playwright this default mechanism is inconsistent
          // https://github.com/JedWatson/react-select/issues/4455#issuecomment-839930597
          blurInputOnSelect={false}
          components={{
            DropdownIndicator: (props) => (
              <DropdownIndicator {...props} forceCaret={forceCaret} />
            ),
            IndicatorSeparator,
            LoadingIndicator,
            ClearIndicator,
            ...(!breakpointUpMd &&
              !forceCaret && { IndicatorsContainer: () => <InvisibleElement /> }),
            Option: (props) => (
              <OptionComponent {...props} chipSize="medium" children={props.children} />
            ),
            MultiValueLabel,
            MultiValueRemove,
            Control,
            MenuList: DesktopMenuList,
            Menu,
            MultiValue: MultiValueComponent,
          }}
        />
      </SelectContainer>
    ),
    [
      className,
      label,
      SelectComponent,
      effectiveOptions,
      value,
      name,
      isSearching,
      placeholder,
      isLoading,
      isClearable,
      isTagClassname,
      hasError,
      breakpointUpMd,
      isSearchable,
      filterOption,
      isDisabled,
      menuIsOpen,
      visible,
      onChange,
      close,
      isMulti,
      onInputSearch,
      show,
      forceCaret,
    ],
  );

  const computeMenuHeight = useCallback(() => {
    const showingInput = isSearchable;
    const isKeyboardOpen = visualViewportHeight !== window.innerHeight;
    const menuHeight =
      visualViewportHeight - // Screen - Keyboard height
      5 - // Drawer mask
      70 - // Header
      (showingInput ? 59 : 0) - // Input
      (isKeyboardOpen ? 0 : 60); // Footer

    setMenuHeight(menuHeight);
  }, [isSearchable, visualViewportHeight]);

  useEffect(() => {
    computeMenuHeight();
  }, [computeMenuHeight]);

  useEffect(() => {
    const handleResize = () => {
      computeMenuHeight();

      if (breakpointUpMd) {
        close();
      }
    };

    window.visualViewport!.addEventListener('resize', handleResize);

    return () => window.visualViewport!.removeEventListener('resize', handleResize);
  }, [breakpointUpMd, close, computeMenuHeight]);

  const height = useBottomDrawerHeight({ fullHeight: true });

  return (
    <SelectContext.Provider
      value={{ onLoadMore, isSearching, setIsLoadingMore, isLoadingMore }}
    >
      {breakpointUpMd ? (
        mainSelect
      ) : (
        <>
          <SelectWrapper className="select-wrapper">
            {mainSelect}
            <SelectInnerWrapper
              onClick={() => {
                if (!isDisabled) {
                  show();
                }
              }}
            />
          </SelectWrapper>
          <StyledDrawer
            menuHeight={menuHeight}
            onClose={handleClose}
            visible={visible}
            destroyOnClose
            push={false}
            placement="bottom"
            closable={false}
            title={
              <DrawerHeader>
                <DrawerTitle>{mobileLabel ?? label}</DrawerTitle>
                <DrawerCloseIcon type="close" onClick={handleClose} />
              </DrawerHeader>
            }
            footer={
              <>
                <DrawerFooter>
                  <ButtonV2 variant="default" size="regular" onClick={handleClear}>
                    {t('common:clear')}
                  </ButtonV2>
                  <ButtonV2
                    variant="primary"
                    size="regular"
                    onClick={() =>
                      handleApply(internalValue as OnChangeValue<Option, IsMulti>)
                    }
                  >
                    {t('common:set')}
                  </ButtonV2>
                </DrawerFooter>
                <SafeAreaBottom />
              </>
            }
            height={height}
          >
            <SelectComponent
              classNamePrefix="lgg-select"
              className={`lgg-drawer-select ${isGroupSelectClassname} ${showingSelectedCountClassName} ${isMultiClassname} ${isTagClassname} ${isMultiGroupSelectClassname}`}
              // @ts-expect-error lib complex types
              options={effectiveOptions}
              value={internalValue}
              isSearchable={isSearchable}
              isMulti={isMulti}
              onInputChange={(value, actionMeta) => {
                setInnerInputValue(value);

                onInputSearch(value, actionMeta);
              }}
              onChange={(newValue) => {
                setInternalValue(newValue);

                if (!isMulti) {
                  handleApply(newValue);
                }
              }}
              menuIsOpen
              filterOption={filterOption}
              hideSelectedOptions={false}
              controlShouldRenderValue={false}
              openMenuOnFocus={false}
              components={{
                Control,
                Input,
                Option: (props) => (
                  <OptionComponent
                    {...props}
                    chipSize="large"
                    children={props.children}
                  />
                ),
                ValueContainer,
                MenuList,
                IndicatorsContainer: () => <InvisibleElement />,
              }}
            />
          </StyledDrawer>
        </>
      )}
    </SelectContext.Provider>
  );
};
