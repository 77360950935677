import account from 'src/i18n/locales/en/account.json';
import activity from 'src/i18n/locales/en/activity.json';
import appointments from 'src/i18n/locales/en/appointments.json';
import calendar from 'src/i18n/locales/en/calendar.json';
import common from 'src/i18n/locales/en/common.json';
import contactTimeline from 'src/i18n/locales/en/contact-timeline.json';
import contacts from 'src/i18n/locales/en/contacts.json';
import conversations from 'src/i18n/locales/en/conversations.json';
import errors from 'src/i18n/locales/en/errors.json';
import header from 'src/i18n/locales/en/header.json';
import instagramChannel from 'src/i18n/locales/en/instagram-channel.json';
import notes from 'src/i18n/locales/en/notes.json';
import notifications from 'src/i18n/locales/en/notifications.json';
import tasks from 'src/i18n/locales/en/tasks.json';
import whatsappChannel from 'src/i18n/locales/en/whatsapp-channel.json';

export const en = {
  account,
  activity,
  appointments,
  calendar,
  common,
  contacts,
  conversations,
  contactTimeline,
  errors,
  header,
  notes,
  notifications,
  tasks,
  whatsappChannel,
  instagramChannel,
};
